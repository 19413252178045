import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { DashboardProvider } from "./providers/Dashboard";
import Theme from "./providers/Theme";
import HealthCheck from "./pages/HealthCheck";
import AuditoriaCadastro from "./pages/AuditoriaCadastro";
import AuditoriaSistema from "./pages/AuditoriaSistema";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuditoriaCadastroById from "./pages/AuditoriaCadastroById";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Theme>
        <Toaster position="top-right" />
        <BrowserRouter>
          <Switch>
            <Route exact path="/health">
              <HealthCheck />
            </Route>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/dashboard/:id">
              <DashboardProvider>
                <Dashboard />
              </DashboardProvider>
            </Route>
            <Route exact path="/auditoria/cadastro">
              <AuditoriaCadastro />
            </Route>
            <Route exact path="/auditoria/cadastro/:id">
              <AuditoriaCadastroById />
            </Route>
            <Route exact path="/auditoria/sistema">
              <AuditoriaSistema />
            </Route>
            <Route path="*">
              <Redirect to={"/"} />
            </Route>
          </Switch>
        </BrowserRouter>
      </Theme>
      {/*DEBUG REACT QUERY*/}
      {/*<ReactQueryDevtools buttonPosition="bottom-left"/>*/}
    </QueryClientProvider>
  );
}

export default App;
