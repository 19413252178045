import { CamposSelect } from "../interfaces";

export function mapToOptions(data: any[], camposDescritores: string[]): CamposSelect[] {
  return data.map((item) => ({
    "@id": item.id,
    id: item.id,
    nome: camposDescritores.map((descricao) => item[descricao]).join(", "),
    ativo: item.ativo ?? true,
    fator: item.fator ?? "",
    projetoCamadaFormularioCampoId: item.projetoCamadaFormularioCampoId ?? "",
    tblNomeColuna: item.tblNomeColuna ?? "",
    tblValorDb: item.id,
    projetoCamadaFormularioCampo: item.projetoCamadaFormularioCampo ?? "",
  }));
}
