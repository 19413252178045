import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Pessoa } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { apiGateway } from "../../../repositories/api.gateway";
import { addPessoaToImobiliario, createPessoa } from "../../../services/data";

export function DialogPessoa(props: { openDialog: boolean; handleCloseDialog: () => void }) {
  const { metadata, setMetadata, errors } = useContext(DashboardContext);
  const [param, setParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [pessoas, setPessoas] = useState<Pessoa[]>([]);

  const getDadosFromPessoa = useCallback(async (field: string) => {
    try {
      const nomeCpf = field.length >= 3 ? field : "default";
      const response = await apiGateway.get(
        `/rest/pessoa?or=(nome.ilike.*${nomeCpf}*,cpf_cnpj.ilike.*${nomeCpf}*,codigo.ilike.*${nomeCpf}*)&limit=20`,
      );

      return response.data;
    } catch (error: any) {
      toast.error(`Erro ao buscar pessoas`);
      console.log(error.message);
      throw new Error(error.message);
    }
  }, []);

  useEffect(() => {
    if (param.length >= 1) {
      setLoading(true);

      getDadosFromPessoa(param)
        .then((data: Pessoa[]) => {
          setPessoas(data);
        })
        .finally(() => setLoading(false));
    }
  }, [getDadosFromPessoa, param]);

  const handleChangeWithDebounce = debounce((value) => {
    setParam(value);
  }, 200);

  function handleAddPessoa() {
    const pessoa = createPessoa();
    const imobiliario_pessoa = addPessoaToImobiliario(pessoa, metadata.id_imobiliario!);
    setMetadata((current) => ({
      ...current,
      id_pessoa: String(pessoa.id),
      id_imobiliario_pessoa: String(imobiliario_pessoa.id),
    }));

    props.handleCloseDialog();
  }

  function handleAddPessoaToImobiliario(pessoa: Pessoa) {
    const imobiliario_pessoa = addPessoaToImobiliario(pessoa, metadata.id_imobiliario!);
    setMetadata((current) => ({
      ...current,
      id_pessoa: String(pessoa.id),
      id_imobiliario_pessoa: String(imobiliario_pessoa.id),
    }));

    props.handleCloseDialog();
  }

  return (
    <Dialog open={props.openDialog} onClose={props.handleCloseDialog}>
      <DialogContent>
        <h2 style={{ textAlign: "center" }}>Selecione uma pessoa para vincular ao imobiliário</h2>

        <TextField
          fullWidth
          variant="standard"
          label="Busque pelo nome, CPF ou CNPJ da pessoa"
          size="small"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            handleChangeWithDebounce(e.target.value)
          }
        />

        <List component="nav">
          {pessoas.map((pessoa: Pessoa, i: number) => {
            return (
              <ListItemButton
                sx={{ borderBottom: "1px solid #5f5f5f" }}
                onClick={() => handleAddPessoaToImobiliario(pessoa)}
              >
                <ListItemText
                  primary={pessoa.nome}
                  secondary={`CPF/CNPJ: ${pessoa.cpf_cnpj || "Não informado"} | Código: ${
                    pessoa.codigo || "Não informado"
                  }`}
                />
              </ListItemButton>
            );
          })}
        </List>

        {loading && (
          <>
            <Skeleton height={45} />
            <Skeleton height={45} />
            <Skeleton height={45} />
          </>
        )}

        {!loading && param !== "" && param.length >= 3 && pessoas.length === 0 && (
          <Typography color={"error"}>
            Sem resultados para a pesquisa de <b>{param}</b>
          </Typography>
        )}
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button color="success" onClick={handleAddPessoa} variant="contained">
          CRIAR NOVA PESSOA
        </Button>
      </DialogActions>
    </Dialog>
  );
}
