import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Collapse,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  useTheme,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { apiGateway } from "../../../repositories/api.gateway";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useHistory } from "react-router-dom";

const colunas = [
  { value: "id", label: "ID" },
  { value: "usuario", label: "Usuário" },
  { value: "email", label: "Email" },
  { value: "sucesso", label: "Sucesso" },
  { value: "data_tentativa", label: "Data" },
  { value: "ip_usuario", label: "IP" },
  { value: "razao_erro", label: "Erro" },
];

export default function TabelaAuditoriaSistema() {
  const [open, setOpen] = useState(true);
  const [colunaSelecionada, setColunaSelecionada] = useState("");
  const [filtro, setFiltro] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const theme = useTheme();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const colunaParam = params.get("coluna") || "";
    const filtroParam = params.get("filtro") || "";

    setColunaSelecionada(colunaParam);
    setFiltro(filtroParam);

    if (colunaParam || filtroParam) {
      setOpen(true);
      if (colunaParam && filtroParam) {
        handleSearch();
      }
    }
  }, [location.search]);

  function isValidSearch() {
    return colunaSelecionada && filtro;
  }

  function generateQueryParams() {
    const params = new URLSearchParams();

    if (colunaSelecionada === "sucesso") {
      const valorSucesso = filtro.toLowerCase() === "sim" ? "true" : "false";
      params.append("sucesso", `eq.${valorSucesso}`);
    } else if (colunaSelecionada === "id" && filtro) {
      params.append("id", `eq.${filtro}`);
    } else if (colunaSelecionada && filtro) {
      params.append(colunaSelecionada, `ilike.*${filtro}*`);
    }

    params.append("limit", rowsPerPage.toString());
    params.append("offset", (page * rowsPerPage).toString());

    return params.toString();
  }

  const {
    data: rowsData = [],
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["auditoria-login", location.search, page, rowsPerPage],
    queryFn: async () => {
      const queryParams = generateQueryParams();
      const response = await apiGateway(`/plataforma/rest/auditoria_login?${queryParams}`);
      const totalRecords = parseInt(response.headers["content-range"].split("/")[1], 10);
      setTotalRecords(totalRecords || 0);

      return response.data;
    },
    staleTime: 120000,
  });

  function handleSearch() {
    if (isValidSearch()) {
      const queryParams = generateQueryParams();
      history.push({
        pathname: window.location.pathname,
        search: queryParams ? `?coluna=${colunaSelecionada}&filtro=${filtro}` : "",
      });
      setPage(0);
      refetch();
    }
  }

  function clearFilters() {
    setColunaSelecionada("");
    setFiltro("");
    setPage(0);
    history.replace(window.location.pathname);
    setTimeout(() => {
      refetch();
    }, 0);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    refetch();
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    refetch();
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" padding={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box padding={2} textAlign="center">
        Erro ao carregar os dados: {error.message}
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: "16px 24px",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
          marginLeft: theme.spacing(4),
          marginRight: theme.spacing(4),
        }}
      >
        <Box sx={{ fontWeight: "bold", fontSize: 18 }}>Acessos ao sistema</Box>
        <Box sx={{ flexGrow: 1, textAlign: "center", fontSize: 14 }}>Veja todos os acessos realizados no sistema</Box>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          margin: theme.spacing(4),
        }}
      >
        <Box sx={{ width: "100%", overflowX: "auto", padding: "16px" }}>
          <Box marginBottom={3} display="flex" gap={2} flexWrap="wrap" alignItems="center">
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel id="select-coluna">Coluna</InputLabel>
              <Select
                labelId="select-coluna"
                value={colunaSelecionada}
                onChange={(e) => setColunaSelecionada(e.target.value)}
                label="Coluna"
              >
                {colunas.map((coluna) => (
                  <MenuItem key={coluna.value} value={coluna.value}>
                    {coluna.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Filtro"
              variant="outlined"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
              sx={{ flex: 1, padding: "8px 0", minWidth: 200 }}
            />

            <Button
              color="success"
              onClick={handleSearch}
              sx={{ width: "143.7px", height: "56px" }}
              variant="contained"
              disabled={!isValidSearch()}
            >
              Filtrar
            </Button>

            <Button
              color="warning"
              onClick={clearFilters}
              sx={{ width: "143.7px", height: "56px" }}
              variant="contained"
            >
              Limpar Filtros
            </Button>
          </Box>

          <Table sx={{ minWidth: 750, borderRadius: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "16px" }}>ID</TableCell>
                <TableCell sx={{ padding: "16px" }}>Usuário</TableCell>
                <TableCell sx={{ padding: "16px" }}>Email</TableCell>
                <TableCell sx={{ padding: "16px" }}>Sucesso</TableCell>
                <TableCell sx={{ padding: "16px" }}>Data</TableCell>
                <TableCell sx={{ padding: "16px" }}>IP</TableCell>
                <TableCell sx={{ padding: "16px" }}>Erro</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rowsData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ padding: "16px" }}>{row.id}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.usuario}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.email}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.sucesso ? "Sim" : "Não"}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.data_tentativa}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.ip_usuario}</TableCell>
                  <TableCell sx={{ padding: "16px" }}>{row.razao_erro}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            labelRowsPerPage={"Linhas por página"}
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            sx={{ marginTop: 2 }}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
