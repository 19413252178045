import AddIcon from "@mui/icons-material/Add";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { Button, Dialog, DialogContent, Fab, Slide, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { TransitionProps } from "react-transition-group/Transition";
import { Midia } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { createMidia, removeMidia } from "../../../services/data";
import { getAll } from "../../../services/db";
import Form from "../Form";
import "./styles.css";

export default function Media() {
  const [value, setValue] = useState(0);
  const [midias, setMidias] = useState<Midia[]>();
  const [selectedMidia, setSelectedMidia] = useState<Midia>();
  const [openDialog, setOpenDialog] = useState(false);
  const [viewingMediaFrom, setViewingMediaFrom] = useState<"lote" | "imobiliario">("lote");
  const [showSwitcherText, setShowSwitcherText] = useState(false);
  const [showAddButtonText, setAddButtonText] = useState(false);

  const theme = useTheme();

  function handleOpenDialog() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const { metadata, setMetadata } = useContext(DashboardContext);

  useEffect(() => {
    const midias = getAll("midia") as Midia[];
    setMidias(midias);
  }, [metadata]);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleAddMedia() {
    fileInputRef.current?.click();
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    const extension = file?.name.slice(((file?.name.lastIndexOf(".") - 1) >>> 0) + 2);
    const name = file?.name.split('.' + extension!)[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = (reader.result as string).split(",")[1];
        const newMidia = await createMidia(metadata[`id_${viewingMediaFrom}`]!, viewingMediaFrom, {
          base64: base64,
          extensao: extension!,
          nome: name!,
        });
        setSelectedMidia(newMidia);
        setMetadata((current) => ({
          ...current,
          id_midia: String(newMidia.id),
        }));
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
      };
    }
  }

  function handleMediaSwitcher() {
    if (viewingMediaFrom === "lote") {
      if (!metadata.id_imobiliario) {
        toast.error("Selecione um imóvel para visualizar as mídias");
        return;
      }

      setViewingMediaFrom("imobiliario");
    } else {
      setViewingMediaFrom("lote");
    }
  }

  function filterFunction(e: Midia) {
    if (viewingMediaFrom === "imobiliario")
      return e.nome_camada === viewingMediaFrom && String(e.id_origem) === metadata.id_imobiliario;

    return true;
  }

  function handleSelectMidia(midia: Midia) {
    setMetadata((current) => ({
      ...current,
      [`id_midia`]: String(midia.id),
    }));
    setSelectedMidia(midia);
    handleOpenDialog();
  }

  function handleRemoveMidia(midia: Midia) {
    removeMidia(String(midia.id));
    setMetadata((current) => ({ ...current, id_midia: undefined }));
    handleCloseDialog();
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 50px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          {selectedMidia && (
            <Box>
              {selectedMidia.tipo === 1 ||
              ("extensao" in selectedMidia && ["jpg", "jpeg", "webp", "png"].includes(selectedMidia.extensao)) ? (
                <img className="dialog-image" src={selectedMidia && selectedMidia.link} alt="img" />
              ) : (
                <Button fullWidth color="success" variant="contained" href={selectedMidia.link} target="_blank">
                  Abrir arquivo
                </Button>
              )}

              <Button
                sx={{ marginTop: theme.spacing(1) }}
                fullWidth
                color="error"
                variant="contained"
                onClick={() => handleRemoveMidia(selectedMidia)}
              >
                Remover arquivo
              </Button>

              {metadata.formularios && <Form formularios={metadata.formularios?.midia} tabela="midia" />}
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} sx={{ width: "95%" }}>
        {midias && midias.length === 0 && <Tab label="Nenhuma mídia" value={0} />}
        {midias &&
          midias.filter(filterFunction).map((midia, index) => {
            return (
              <Tab
                sx={{
                  minWidth: "100%",
                }}
                key={index}
                label={
                  "extensao" in midia && ["jpg", "jpeg", "webp", "png"].includes(midia.extensao) ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img className="carousel-image" src={midia.link} alt="img" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="caption">Arquivo</Typography>
                      <Typography>{midia.nome + "." + midia.extensao}</Typography>
                    </Box>
                  )
                }
                onClick={() => handleSelectMidia(midia)}
                value={index}
              />
            );
          })}
      </Tabs>

      <Box
        id="media-buttons-container"
        sx={{
          position: "absolute",
          right: "10px",
          bottom: "15px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Fab
          sx={{ marginBottom: "10px" }}
          variant="extended"
          color="success"
          onClick={handleMediaSwitcher}
          onMouseOver={() => setShowSwitcherText(true)}
          onMouseLeave={() => setShowSwitcherText(false)}
        >
          <SwapVerticalCircleIcon />
          {showSwitcherText && (
            <>
              &nbsp;
              {viewingMediaFrom === "imobiliario" ? "Visualizar midias do lote" : "Visualizar midias do imobiliário"}
            </>
          )}
        </Fab>
        <Fab
          variant="extended"
          color="success"
          onClick={handleAddMedia}
          onMouseOver={() => setAddButtonText(true)}
          onMouseLeave={() => setAddButtonText(false)}
        >
          <AddIcon />
          {showAddButtonText && (
            <>
              &nbsp;
              {viewingMediaFrom === "imobiliario" ? "Adicionar mídia ao imobiliário" : "Adicionar mídia ao lote"}
            </>
          )}
          <input type="file" style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
        </Fab>
      </Box>
    </Box>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
